import Home from './Home';
import HomeNew from './HomeNew';

function App() {
  const searchParams = new URLSearchParams(document.location.search);
  const folio = searchParams.get('f');
  return (

    <div className="App">
      <HomeNew></HomeNew>
    </div>
  );
}

export default App;
