import "./loader.css";

const Error = (props) => {
  const { isActive } = props;
  return (
    <>
      {isActive ? (
        <div className="loader">
            <h1>EL folio ingresado es inválido</h1>
        </div>
      ) : null}
    </>
  );
};

export default Error;
